import { makeStyles } from '@material-ui/core'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import React from 'react'
import Svg from 'react-inlinesvg'
import LazyLoad from 'react-lazyload'
import Hero from '../../components/Banners/Hero'
import CustomerCarousel from '../../components/Consumer/CustomerCarousel'
import DevicesCarousel from '../../components/Consumer/DevicesCarousel'
import FloatingBlock from '../../components/Consumer/FloatingBlock'
import HeroConsumer from '../../components/Consumer/HeroConsumer'
import ItemCarousel from '../../components/Consumer/ItemCarousel'
import PhonePlans from '../../components/Consumer/PhonePlans'
import TwoBlocks from '../../components/Consumer/TwoBlocks'
import ConsumerLayout from '../../components/Layouts/ConsumerLayout'
import FAQList from '../../components/Other/FAQList'
import Logos from '../../components/Other/Logos'
import LocaleProvider from '../../contexts/LocaleProvider'
import CombinedBlueLarge from '../../images/bgimages/combined-blue2-large.svg'
import CombinedBlue2a from '../../images/bgimages/combined-blue2a.svg'
import CombinedGrey from '../../images/bgimages/combined-grey.svg'
import HalfCircle from '../../images/bgimages/half-circle.svg'

const useStyles = makeStyles((theme) => ({
  gradient: {
    position: 'relative',
    overflow: 'hidden',
    backgroundImage:
      'linear-gradient(to bottom, #299dc1, rgba(41, 157, 193, 0.8) 50%, rgba(41, 157, 193, 0.6))',
    padding: '3rem 0 0',
    [theme.breakpoints.up('md')]: {
      padding: '10rem 0 0'
    }
  },
  devicesCarouselBgImage: {
    position: 'absolute',
    zIndex: 0,
    bottom: '-80%',
    width: 1370,
    left: '58%',
    transform: 'translateX(-50%)'
  },
  devicesCarouselBottom: {
    position: 'relative',
    backgroundColor: '#f7f6f6',
    height: '7rem',
    padding: '3rem 0 0'
  },
  bgGrey: {
    backgroundColor: '#f7f6f6',
    position: 'relative',
    overflow: 'hidden'
  },
  bgWhite: {
    backgroundColor: '#ffffff',
    position: 'relative'
  },
  halfCircle: {
    position: 'absolute',
    left: -500,
    top: -405,
    zIndex: 0,
    width: 1500,
    maxWidth: 'none',
    [theme.breakpoints.up('md')]: {
      top: -505,
      width: 2880,
      height: 1604
    },
    '@media (min-width: 2360px)': {
      width: 3500,
      height: 2204
    }
  },
  relative: {
    position: 'relative'
  },
  circle1: {
    position: 'absolute',
    left: -600,
    bottom: 0,
    '@media (min-width: 960px)': {
      left: -450
    }
  },
  circle2: {
    position: 'absolute',
    right: -600,
    bottom: 180,
    '@media (min-width: 960px)': {
      right: -450
    }
  },
  circleBlue: {
    display: 'none',
    '@media (min-width: 960px)': {
      display: 'block',
      position: 'absolute',
      right: 60,
      bottom: 180,
      width: 150
    }
  },
  hero: {
    position: 'relative',
    overflow: 'hidden',
    paddingTop: 40
  }
}))

function Smartphones({
  data: {
    datoCmsMyTruphoneLandingPage,
    allMyTruphonePlan: { nodes: plans },
    datoCmsSite
  },
  pageContext: { menu, cookiePrompt, footer, locale }
}) {
  const classes = useStyles(datoCmsMyTruphoneLandingPage)
  return (
    <ConsumerLayout menu={menu} footer={footer} cookiePrompt={cookiePrompt}>
      <HelmetDatoCms
        seo={datoCmsMyTruphoneLandingPage.seoMetaTags}
        favicon={datoCmsSite.faviconMetaTags}
      />
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className={classes.hero}>
        <HeroConsumer
          phone={true}
          hidemobile={true}
          bgcolor="gradient"
          image={datoCmsMyTruphoneLandingPage.heroImage}
          topText={datoCmsMyTruphoneLandingPage.title}
          title={datoCmsMyTruphoneLandingPage.subtitle}
          content={datoCmsMyTruphoneLandingPage.description}
          ctas={[
            {
              href: datoCmsMyTruphoneLandingPage.appStoreUrl,
              image: datoCmsMyTruphoneLandingPage.appStoreImage,
              appStore: 'Apple'
            },
            {
              href: datoCmsMyTruphoneLandingPage.playStoreUrl,
              image: datoCmsMyTruphoneLandingPage.playStoreImage,
              appStore: 'Google'
            }
          ]}
          selectDevices={[
            {
              compatibilityTitle: datoCmsMyTruphoneLandingPage.compatibilityTitle,
              compatibilityDescription: datoCmsMyTruphoneLandingPage.compatibilityDescription,
              compatibilityPlaceholderText:
                datoCmsMyTruphoneLandingPage.compatibilityPlaceholderText,
              compatibilityModels: datoCmsMyTruphoneLandingPage.compatibilityDevices.map(
                (model) => ({ label: model.text, value: model.text.split(' ').join('_') })
              ),
              compatibilityCtaText: datoCmsMyTruphoneLandingPage.compatibilityCtaText,
              compatibilityCtaOnClick: (value) => {
                if (value.indexOf('iPhone') > -1) {
                  window.open(datoCmsMyTruphoneLandingPage.appStoreUrl)
                } else {
                  window.open(datoCmsMyTruphoneLandingPage.playStoreUrl)
                }
              }
            }
          ]}
        />

        <div className={classes.gradient}>
          <Svg src={CombinedBlueLarge} alt="" className={classes.devicesCarouselBgImage} />
          <DevicesCarousel
            minHeight={360}
            autoplay={true}
            list={datoCmsMyTruphoneLandingPage.productCarouselSlides.map((slide) => {
              return {
                topTitle: slide.topTitle,
                title: slide.title,
                subtitle: slide.content,
                iphoneImage: slide.iphoneImage,
                androidImage: slide.androidImage
              }
            })}
            deviceImage={datoCmsMyTruphoneLandingPage.productCarouselAndroidOutline}
            iphoneImage={datoCmsMyTruphoneLandingPage.productCarouselIphoneOutline}
            bgcolor={false}
            iPhone={true}
            textBottom={datoCmsMyTruphoneLandingPage.productCarouselFooterLink}
          />
          <div className={classes.devicesCarouselBottom}></div>
        </div>
      </div>
      <LazyLoad once offset={300} height={1500}>
        <div className={classes.bgGrey}>
          <div className={classes.relative}>
            <Svg src={CombinedGrey} alt="" className={classes.circle1} />
            <Svg src={CombinedGrey} alt="" className={classes.circle2} />
            <Svg src={CombinedBlue2a} alt="" className={classes.circleBlue} />
            <LocaleProvider currencies={['EUR', 'GBP', 'USD', 'AUD', 'HKD', 'JPY', 'PLN', 'CHF']}>
              <PhonePlans
                locale={locale}
                currencies={['EUR', 'GBP', 'USD', 'AUD', 'HKD', 'JPY', 'PLN', 'CHF']}
                plans={plans}
                title={datoCmsMyTruphoneLandingPage.planCoverageTitle}
                durationSingularLabel={datoCmsMyTruphoneLandingPage.planCoveragePlanDurationDay}
                durationLabel={datoCmsMyTruphoneLandingPage.planCoveragePlanDurationDays}
                globalPlanText={datoCmsMyTruphoneLandingPage.planCoverageGlobalTabText}
                europePlanText={datoCmsMyTruphoneLandingPage.planCoverageEuropePlanText}
                usaPlusPlanText={datoCmsMyTruphoneLandingPage.planCoverageUsaPlusPlanText}
                northAmericaPlanText={datoCmsMyTruphoneLandingPage.planCoverageNorthAmericaPlanText}
                apacPlanText={datoCmsMyTruphoneLandingPage.planCoverageApacPlanText}
                showMoreText={datoCmsMyTruphoneLandingPage.planCoverageShowMoreText}
                freePlanEnabled={datoCmsMyTruphoneLandingPage.planCoverageFreePlanEnabled}
                freePlanAllowance={datoCmsMyTruphoneLandingPage.planCoverageFreePlanAllowance}
                freePlanPrice={datoCmsMyTruphoneLandingPage.planCoverageFreePlanPrice}
                freePlanDuration={datoCmsMyTruphoneLandingPage.planCoverageFreePlanDuration}
                freePlanUnit={datoCmsMyTruphoneLandingPage.planCoverageFreePlanUnit}
                searchPlaceholder={datoCmsMyTruphoneLandingPage.planCoverageSearchPlaceholder}
                noResultsText={datoCmsMyTruphoneLandingPage.planCoverageSearchNoResults}
                anchorId={datoCmsMyTruphoneLandingPage.planCoverageAnchorId}
                localTabText={datoCmsMyTruphoneLandingPage.planCoverageLocalTabText}
                regionalTabText={datoCmsMyTruphoneLandingPage.planCoverageRegionalTabText}
                globalTabText={datoCmsMyTruphoneLandingPage.planCoverageGlobalTabText}
                allRegionsText={datoCmsMyTruphoneLandingPage.planCoverageAllRegionsText}
                coverageTitle={datoCmsMyTruphoneLandingPage.planCoverageNumberOfCountries}
                coverageDescription={datoCmsMyTruphoneLandingPage.planCoveragePlanDescription}
                howMuchDataLink={Object.create({
                  text: datoCmsMyTruphoneLandingPage.planCoveragePlanFooterLinkText,
                  href: datoCmsMyTruphoneLandingPage.planCoveragePlanFooterLinkUrl,
                  openIn: datoCmsMyTruphoneLandingPage.planCoveragePlanFooterLinkOpenNewTab
                })}
                modal={{
                  store: true,
                  adsEvent: 'free-trial',
                  title: datoCmsMyTruphoneLandingPage.planModalSelectStoreTitle,
                  appStoreImage: datoCmsMyTruphoneLandingPage.appStoreImage,
                  appStoreUrl: datoCmsMyTruphoneLandingPage.appStoreUrl,
                  playStoreImage: datoCmsMyTruphoneLandingPage.playStoreImage,
                  playStoreUrl: datoCmsMyTruphoneLandingPage.playStoreUrl
                }}
              />
            </LocaleProvider>
            {datoCmsMyTruphoneLandingPage.reviewsReviews &&
              datoCmsMyTruphoneLandingPage.reviewsReviews.length > 0 && (
                <CustomerCarousel
                  locale={locale}
                  title={datoCmsMyTruphoneLandingPage.reviewsTitle}
                  list={datoCmsMyTruphoneLandingPage.reviewsReviews}
                  bgcolor="grey"
                  autoplay="true"
                />
              )}
            {datoCmsMyTruphoneLandingPage.companyLogos &&
              datoCmsMyTruphoneLandingPage.companyLogos.length > 0 && (
                <Logos
                  noBorders={true}
                  hover={true}
                  logos={datoCmsMyTruphoneLandingPage.companyLogos}
                />
              )}
          </div>
          {datoCmsMyTruphoneLandingPage.cardCarouselCards &&
            datoCmsMyTruphoneLandingPage.cardCarouselCards.length > 0 && (
              <ItemCarousel
                bgcolor="dark"
                type="bluebox"
                title={datoCmsMyTruphoneLandingPage.cardCarouselTitle}
                content={datoCmsMyTruphoneLandingPage.cardCarouselDescription}
                list={datoCmsMyTruphoneLandingPage.cardCarouselCards.map((card) => {
                  return {
                    title: card.title,
                    content: card.description,
                    icon: card.icon
                  }
                })}
              />
            )}
          <div className={classes.relative}>
            <Svg className={classes.halfCircle} src={HalfCircle} alt="half circle" />
            <TwoBlocks
              color="white"
              leftIconTextBlock={Object.create({
                title: datoCmsMyTruphoneLandingPage.twoItemIconTextAreaLeftTitle,
                description: datoCmsMyTruphoneLandingPage.twoItemIconTextAreaLeftText,
                imageSrc: datoCmsMyTruphoneLandingPage.twoItemIconTextAreaLeftIcon,
                cta: {
                  text: datoCmsMyTruphoneLandingPage.twoItemIconTextAreaLeftCtaText,
                  href: datoCmsMyTruphoneLandingPage.twoItemIconTextAreaLeftCtaLink,
                  openIn: datoCmsMyTruphoneLandingPage.twoItemIconTextAreaLeftCtaOpenNewTab
                    ? '_blank'
                    : ''
                }
              })}
              rightIconTextBlock={Object.create({
                title: datoCmsMyTruphoneLandingPage.twoItemIconTextAreaRightTitle,
                description: datoCmsMyTruphoneLandingPage.twoItemIconTextAreaRightText,
                imageSrc: datoCmsMyTruphoneLandingPage.twoItemIconTextAreaRightIcon,
                cta: {
                  text: datoCmsMyTruphoneLandingPage.twoItemIconTextAreaRightCtaText,
                  href: datoCmsMyTruphoneLandingPage.twoItemIconTextAreaRightCtaLink,
                  openIn: datoCmsMyTruphoneLandingPage.twoItemIconTextAreaRightCtaOpenNewTab
                    ? '_blank'
                    : ''
                }
              })}
            />
          </div>
          <FloatingBlock
            right="25px"
            colortop="#48abc9"
            colorbottom="#023240"
            title={datoCmsMyTruphoneLandingPage.footerTitle}
            footerImage={datoCmsMyTruphoneLandingPage.footerImage}
            subtitle={datoCmsMyTruphoneLandingPage.footerDescription}
            ctas={[
              {
                image: datoCmsMyTruphoneLandingPage.appStoreImage,
                href: datoCmsMyTruphoneLandingPage.appStoreUrl,
                appStore: 'Apple'
              },
              {
                image: datoCmsMyTruphoneLandingPage.playStoreImage,
                href: datoCmsMyTruphoneLandingPage.playStoreUrl,
                appStore: 'Google'
              }
            ]}
          />
        </div>
      </LazyLoad>
      <Hero
        bgColor="#01313f"
        color="#ffffff"
        title={datoCmsMyTruphoneLandingPage.contactSupportBannerTitle}
        subtitle={datoCmsMyTruphoneLandingPage.contactSupportBannerDescription}
        ctas={[
          {
            text: datoCmsMyTruphoneLandingPage.contactSupportBannerCtaText,
            href: datoCmsMyTruphoneLandingPage.contactSupportBannerCtaLink,
            target: datoCmsMyTruphoneLandingPage.contactSupportBannerCtaOpenNewTab ? '_blank' : ''
          }
        ]}
      />

      {datoCmsMyTruphoneLandingPage.faqItems.length > 0 && (
        <div className={classes.bgWhite}>
          <FAQList
            feedbackEnabled={false}
            large={true}
            title={datoCmsMyTruphoneLandingPage.faqTitle}
            list={datoCmsMyTruphoneLandingPage.faqItems}
          />
        </div>
      )}
    </ConsumerLayout>
  )
}

export default Smartphones

export const query = graphql`
  query ConsumerMyTruphonePage($locale: String!) {
    datoCmsSite {
      faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
      }
    }
    datoCmsMyTruphoneLandingPage(locale: { eq: $locale }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      subtitle
      description
      appStoreImage {
        url
        gatsbyImageData(height: 42)
      }
      appStoreUrl
      playStoreImage {
        url
        gatsbyImageData(height: 42)
      }
      playStoreUrl
      heroImage {
        url
        gatsbyImageData(width: 523)
      }
      compatibilityTitle
      compatibilityDescription
      compatibilityPlaceholderText
      compatibilityDevices {
        text
        store
      }
      compatibilityCtaText
      productCarouselIphoneOutline {
        url
        gatsbyImageData(width: 378)
      }
      productCarouselAndroidOutline {
        url
        gatsbyImageData(width: 378)
      }
      productCarouselSlides {
        topTitle
        title
        content
        iphoneImage {
          url
          gatsbyImageData(width: 248)
        }
        androidImage {
          url
          gatsbyImageData(width: 248)
        }
      }
      productCarouselFooterLink
      aboutTruphoneTitle
      aboutTruphoneDescription
      aboutTruphoneItems {
        text
        icon {
          url
          gatsbyImageData
        }
      }
      esimFloatingBlockIcon {
        url
        gatsbyImageData(width: 32, height: 32)
      }
      esimFloatingBlockTitle
      esimFloatingBlockDescription
      esimFloatingBlockCtaText
      esimFloatingBlockCtaLink
      esimFloatingBlockCtaOpenNewTab
      planCoverageAnchorId
      planCoverageTitle
      planCoverageDefaultCurrency
      planCoverageSearchPlaceholder
      planCoverageSearchNoResults
      planCoverageLocalTabText
      planCoverageRegionalTabText
      planCoverageGlobalTabText
      planCoverageAllRegionsText
      planCoverageShowMoreText
      planCoverageEuropePlanText
      planCoverageUsaPlusPlanText
      planCoverageNorthAmericaPlanText
      planCoverageApacPlanText
      planCoverageGlobalPlanText
      planCoverageNumberOfCountries
      planCoveragePlanDescription
      planCoveragePlanDurationDay
      planCoveragePlanDurationDays
      planCoveragePlanFooterLinkText
      planCoveragePlanFooterLinkUrl
      planCoveragePlanFooterLinkOpenNewTab
      planCoverageFreePlanEnabled
      planCoverageFreePlanAllowance
      planCoverageFreePlanUnit
      planCoverageFreePlanDuration
      planCoverageFreePlanPrice
      planModalSelectStoreTitle
      reviewsTitle
      reviewsReviews {
        title
        content
        date
      }
      cardCarouselCards {
        title
        description
        icon {
          url
          gatsbyImageData(height: 150)
        }
      }
      companyLogos {
        image {
          url
          gatsbyImageData(height: 60)
        }
        link
        openNewTab
      }
      twoItemIconTextAreaLeftIcon {
        url
        gatsbyImageData(height: 90, width: 122)
      }
      twoItemIconTextAreaLeftTitle
      twoItemIconTextAreaLeftText
      twoItemIconTextAreaLeftCtaText
      twoItemIconTextAreaLeftCtaLink
      twoItemIconTextAreaLeftCtaOpenNewTab
      twoItemIconTextAreaRightIcon {
        url
        gatsbyImageData(height: 90, width: 122)
      }
      twoItemIconTextAreaRightTitle
      twoItemIconTextAreaRightText
      twoItemIconTextAreaRightCtaText
      twoItemIconTextAreaRightCtaLink
      twoItemIconTextAreaLeftCtaOpenNewTab
      footerImage {
        url
        gatsbyImageData(width: 460)
      }
      footerTitle
      footerDescription
      contactSupportBannerTitle
      contactSupportBannerDescription
      contactSupportBannerCtaText
      contactSupportBannerCtaLink
      contactSupportBannerCtaOpenNewTab
      faqTitle
      faqItems {
        faqId
        title
        content
      }
    }
    allMyTruphonePlan(filter: { locale: { eq: $locale } }) {
      nodes {
        footprintCountries {
          iso
          name
        }
        footprintType
        name
        region
        iso
        iconName
        allowances {
          allowance
          unit
          duration
          prices {
            currency
            price
          }
        }
      }
    }
  }
`
