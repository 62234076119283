import React from 'react'
import { Container, Typography, Box, makeStyles } from '@material-ui/core'
import { TitleH2Bold, SectionHeaderSmall } from '../../theme/typography'
import PlanTabs from './PlanTabs'
import PropTypes from 'proptypes'
import styled from 'styled-components'
import Svg from 'react-inlinesvg'
import WorldLeft from '../../images/bgimages/images-world-map-left.svg'
import WorldRight from '../../images/bgimages/images-world-map-right.svg'

const useStyles = makeStyles((theme) => ({
  worldMapLeft: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      position: 'absolute !important',
      top: 100,
      left: -250
    },
    '@media (min-width: 1400px)': {
      left: -100
    },
    '@media (min-width: 1700px)': {
      left: 0
    }
  },
  worldMapRight: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      position: 'absolute !important',
      top: 100,
      right: -320
    },
    [theme.breakpoints.up('lg')]: {
      right: -250
    },
    '@media (min-width: 1400px)': {
      right: -150
    },
    '@media (min-width: 1700px)': {
      right: 0
    }
  }
}))

const PlansBox = styled(Box)(() => ({
  position: 'relative',
  minHeight: 1000
}))
const MainContainer = styled(Container)(() => ({
  position: 'relative'
}))
const TitleTop = styled(SectionHeaderSmall)(({ theme }) => ({
  marginBottom: theme.spacing(1)
}))
const ContentText = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(5)
}))

function PhonePlans(props) {
  const classes = useStyles(props)
  const translatedPlans = props.plans
  const regions = props.plans
    .filter((x) => x.footprintType === 'Local')
    .map((x) => x.region)
    .filter((item, index, list) => list.indexOf(item) === index)

  const countries = props.plans
    .filter((x) => x.footprintType === 'Local')
    .map((x) => ({ iso: x.iso, name: x.name, region: x.region }))

  return (
    <PlansBox pt={14} pb={7}>
      <Svg className={classes.worldMapLeft} src={WorldLeft} alt="world map" />
      <Svg className={classes.worldMapRight} src={WorldRight} alt="world map" />
      <MainContainer maxWidth="lg" align="center">
        <TitleTop component="h1" gutterBottom>
          {props.titleTop}
        </TitleTop>
        <TitleH2Bold component="h2" gutterBottom>
          {props.title}
        </TitleH2Bold>
        <ContentText variant="body1" color="textSecondary">
          {props.content}{' '}
        </ContentText>
        <PlanTabs {...props} plans={translatedPlans} countries={countries} regions={regions} />
      </MainContainer>
    </PlansBox>
  )
}

export default PhonePlans

PhonePlans.propTypes = {
  title: PropTypes.string,
  topText: PropTypes.string,
  content: PropTypes.string
}

PhonePlans.defaultProps = {
  plans: []
}
